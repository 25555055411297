import React, { useState, useRef, useCallback, useEffect } from "react";
import BreadCrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import Modal from "Common/Components/Modal";
import { ToastContainer, toast } from "react-toastify";

// Icons
import {
  Trash2,
  Save,
  Minus,
  Plus,
  Eye,
  RefreshCcw,
  Download,
} from "lucide-react";
import { Link } from "react-router-dom";
import moment from "moment";

interface IAccount {
  accountName: string;
  quantity: number;
  totalCost: number;
}
interface IAccountData {
  accountId: string;
  accountName: number;
}

interface IVendor {
  vendorId: string;
  vendorName: string;
}

const UploadInvoice = () => {
  const [accounts, setAccounts] = useState([] as IAccount[]);
  const [accountsData, setAccountData] = useState([] as IAccountData[]);
  const [vendors, setVendors] = useState([] as IVendor[]);

  const [formData, setFormData] = useState({
    FormName: "AddInvoice",
    vendorId: "",
    account: "",
    invoiceType: "",
    invoiceNumber: "",
    invoiceDate: new Date(),
    PONumber: "",
    paidType: "",
    userId: 1,
    invoiceAccounts: [] as IAccount[],
  });

  const [newAccount, setNewAccount] = useState({
    accountName: "",
    quantity: 0,
    totalCost: 0,
  } as IAccount);

  const [show, setShow] = useState<boolean>(false);

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [show]);

  useEffect(() => {
    fetchVendors();
    fetchAccounts();
  }, []);

  async function fetchVendors() {
    const url = process.env.REACT_APP_SERVER_ADDRESS + `/api/vendor`;

    const response = await fetch(url);
    const vendorsResponse = await response.json();
    if (vendorsResponse && vendorsResponse.length > 0) {
      const vendorsData = vendorsResponse.map((vendor: any) => {
        return {
          vendorId: vendor.vendorId,
          vendorName: vendor.vendorName,
        };
      });
      setVendors(vendorsData);
    }
  }
  async function fetchAccounts() {
    const url = process.env.REACT_APP_SERVER_ADDRESS + `/api/accounts`;

    const response = await fetch(url);
    const accountsResponse = await response.json();
    if (accountsResponse && accountsResponse.length > 0) {
      const accountsData = accountsResponse.map((account: any) => {
        return {
          accountId: account.accountNumber,
          accountName: account.accountDesc,
        };
      });
      setAccountData(accountsData);
    }
  }

  const addAccount = () => {
    setShow(true);
  };

  const saveAccount = (e: any) => {
    e.preventDefault();

    if (newAccount.accountName === "") {
      alert("Please enter account name");
      return;
    }

    if (newAccount.quantity === 0) {
      alert("Please enter quantity");
      return;
    }

    if (newAccount.totalCost === 0) {
      alert("Please enter total cost");
      return;
    }

    setShow(false);

    setAccounts([...accounts, newAccount]);
    setNewAccount({
      ...newAccount,
      accountName: "",
      quantity: 0,
      totalCost: 0,
    });
  };

  const fileRef = useRef(null);
  function uploadCsvFile() {
    (fileRef?.current as any).click();
  }

  const handleChange = (e: any) => {
    console.log("e.target: ", e.target);

    console.log("e.target.name: ", e.target.name);
    console.log("e.target.value: ", e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAccountModalChange = (e: any) => {
    console.log("e.target: ", e.target);
    console.log("e.target.name: ", e.target.name);
    console.log("e.target.value: ", e.target.value);
    if (e.target.name === "accountName") {
      setNewAccount({ ...newAccount, accountName: e.target.value });
    }

    if (e.target.name === "quantity") {
      setNewAccount({ ...newAccount, quantity: parseFloat(e.target.value) });
    }

    if (e.target.name === "totalCost") {
      setNewAccount({ ...newAccount, totalCost: parseFloat(e.target.value) });
    }
  };

  const onInvoiceDateChange = ([date]: any) => {
    console.log("date: ", date);
    setFormData({ ...formData, invoiceDate: date });
  };

  async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    console.log("File uploaded", event.target.files);
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          process.env.REACT_APP_SERVER_ADDRESS + `/api/document`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          console.log("File uploaded successfully");
        } else {
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Network error occurred", error);
      }
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    formData.invoiceAccounts = accounts;

    console.log("FormData: ", formData);
    try {
      const url = process.env.REACT_APP_SERVER_ADDRESS + `/api/invoice`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      toast.success("Invoice added successfully.", { autoClose: 2000 });
    } catch (error) {
      toast.error("Error saving the invoice", { autoClose: 2000 });
      console.error("Error saving the invoice:", error);
    }
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Upload Invoices" pageTitle="Invoices" />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid items-center grid-cols-1 gap-5 mb-5 xl:grid-cols-12">
        <div className="xl:col-span-2">
          <h5 className="text-16">Upload Invoices</h5>
        </div>
       
      </div>
      <div className="card">
      <div className="card-body">

      <div className="xl:col-span-3 xl:col-start-10">
          <div className="flex justify-middle gap-2">
            <button
              type="button"
              onClick={uploadCsvFile}
              className="text-white bg-purple-500 border-purple-500 btn hover:text-white hover:bg-purple-600 hover:border-purple-600 focus:text-white focus:bg-purple-600 focus:border-purple-600 focus:ring focus:ring-purple-100 active:text-white active:bg-purple-600 active:border-purple-600 active:ring active:ring-purple-100 dark:ring-purple-400/10"
            >
              Upload Invoices
            </button>
            <input
              type="file"
              id="file"
              name="file"
              ref={fileRef}
              hidden
              onChange={handleFileUpload}
            />
          </div>
        </div>

        </div></div>

      
    </React.Fragment>
  );
};

export default UploadInvoice;
