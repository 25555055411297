import React, { useState, useRef, useCallback, useEffect } from "react";
import BreadCrumb from "Common/BreadCrumb";
import Flatpickr from "react-flatpickr";
import Modal from "Common/Components/Modal";
import { ToastContainer, toast } from "react-toastify";

// Icons
import {
  Trash2,
  Save,
  Minus,
  Plus,
  Eye,
  RefreshCcw,
  Download,
} from "lucide-react";
import { Link } from "react-router-dom";
import moment from "moment";

interface IAccount {
  accountName: string;
  quantity: number;
  totalCost: number;
}
interface IAccountData {
  accountId: string;
  accountName: number;
}

interface IVendor {
  vendorId: string;
  vendorName: string;
}

const AddNew = () => {
  const [accounts, setAccounts] = useState([] as IAccount[]);
  const [accountsData, setAccountData] = useState([] as IAccountData[]);
  const [vendors, setVendors] = useState([] as IVendor[]);

  const [formData, setFormData] = useState({
    FormName: "AddInvoice",
    vendorId: "",
    account: "",
    invoiceType: "",
    invoiceNumber: "",
    invoiceDate: new Date(),
    PONumber: "",
    paidType: "",
    userId: 1,
    invoiceAccounts: [] as IAccount[],
  });

  const [newAccount, setNewAccount] = useState({
    accountName: "",
    quantity: 0,
    totalCost: 0,
  } as IAccount);

  const [show, setShow] = useState<boolean>(false);

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [show]);

  useEffect(() => {
    fetchVendors();
    fetchAccounts();
  }, []);

  async function fetchVendors() {
    const url = process.env.REACT_APP_SERVER_ADDRESS + `/api/vendor`;

    const response = await fetch(url);
    const vendorsResponse = await response.json();
    if (vendorsResponse && vendorsResponse.length > 0) {
      const vendorsData = vendorsResponse.map((vendor: any) => {
        return {
          vendorId: vendor.vendorId,
          vendorName: vendor.vendorName,
        };
      });
      setVendors(vendorsData);
    }
  }
  async function fetchAccounts() {
    const url = process.env.REACT_APP_SERVER_ADDRESS + `/api/accounts`;

    const response = await fetch(url);
    const accountsResponse = await response.json();
    if (accountsResponse && accountsResponse.length > 0) {
      const accountsData = accountsResponse.map((account: any) => {
        return {
          accountId: account.accountNumber,
          accountName: account.accountDesc,
        };
      });
      setAccountData(accountsData);
    }
  }

  const addAccount = () => {
    setShow(true);
  };

  const saveAccount = (e: any) => {
    e.preventDefault();

    if (newAccount.accountName === "") {
      alert("Please enter account name");
      return;
    }

    if (newAccount.quantity === 0) {
      alert("Please enter quantity");
      return;
    }

    if (newAccount.totalCost === 0) {
      alert("Please enter total cost");
      return;
    }

    setShow(false);

    setAccounts([...accounts, newAccount]);
    setNewAccount({
      ...newAccount,
      accountName: "",
      quantity: 0,
      totalCost: 0,
    });
  };

  const fileRef = useRef(null);
  function uploadCsvFile() {
    (fileRef?.current as any).click();
  }

  const handleChange = (e: any) => {
    console.log("e.target: ", e.target);

    console.log("e.target.name: ", e.target.name);
    console.log("e.target.value: ", e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAccountModalChange = (e: any) => {
    console.log("e.target: ", e.target);
    console.log("e.target.name: ", e.target.name);
    console.log("e.target.value: ", e.target.value);
    if (e.target.name === "accountName") {
      setNewAccount({ ...newAccount, accountName: e.target.value });
    }

    if (e.target.name === "quantity") {
      setNewAccount({ ...newAccount, quantity: parseFloat(e.target.value) });
    }

    if (e.target.name === "totalCost") {
      setNewAccount({ ...newAccount, totalCost: parseFloat(e.target.value) });
    }
  };

  const onInvoiceDateChange = ([date]: any) => {
    console.log("date: ", date);
    setFormData({ ...formData, invoiceDate: date });
  };

  async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    console.log("File uploaded", event.target.files);
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(
          process.env.REACT_APP_SERVER_ADDRESS + `/api/document`,
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          console.log("File uploaded successfully");
        } else {
          console.error("Failed to upload file");
        }
      } catch (error) {
        console.error("Network error occurred", error);
      }
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    formData.invoiceAccounts = accounts;

    console.log("FormData: ", formData);
    try {
      const url = process.env.REACT_APP_SERVER_ADDRESS + `/api/invoice`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      toast.success("Invoice added successfully.", { autoClose: 2000 });
    } catch (error) {
      toast.error("Error saving the invoice", { autoClose: 2000 });
      console.error("Error saving the invoice:", error);
    }
  };

  return (
    <React.Fragment>
      <BreadCrumb title="Add New" pageTitle="Invoices" />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid items-center grid-cols-1 gap-5 mb-5 xl:grid-cols-12">
        <div className="xl:col-span-2">
          <h5 className="text-16">New Invoice</h5>
        </div>
        <div className="xl:col-span-3 xl:col-start-10">
          <div className="flex justify-end gap-2">
            <button
              type="button"
              onClick={uploadCsvFile}
              className="text-white bg-purple-500 border-purple-500 btn hover:text-white hover:bg-purple-600 hover:border-purple-600 focus:text-white focus:bg-purple-600 focus:border-purple-600 focus:ring focus:ring-purple-100 active:text-white active:bg-purple-600 active:border-purple-600 active:ring active:ring-purple-100 dark:ring-purple-400/10"
            >
              Upload Invoices
            </button>
            <input
              type="file"
              id="file"
              name="file"
              ref={fileRef}
              hidden
              onChange={handleFileUpload}
            />
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <h6 className="mb-4 text-gray-800 underline text-16 dark:text-zink-50">
              Vendor Info:
            </h6>
            <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
              <div className="xl:col-span-5">
                <label
                  htmlFor="vendor"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Select Vendor
                </label>
                <select
                  id="vendor"
                  name="vendorId"
                  onChange={handleChange}
                  value={formData.vendorId}
                  className="form-select border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  required
                >
                  <option value="" disabled>
                    Select Vendor
                  </option>
                  {vendors.map((vendor, index) => (
                    <option key={index} value={vendor.vendorId}>
                      {vendor.vendorName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <h6 className="my-5 underline text-16">Invoice Info:</h6>
            <div className="grid grid-cols-1 gap-5 xl:grid-cols-12 changeAddress">
              <div className="xl:col-span-3">
                <label
                  htmlFor="invoiceType"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Invoice Type
                </label>
                <select
                  onChange={handleChange}
                  value={formData.invoiceType}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  data-choices
                  data-choices-search-false
                  name="invoiceType"
                  id="invoiceType"
                >
                  <option defaultValue="">Select Type</option>
                  <option value="Invoice">Invoice</option>
                  <option value="Credit">Credit</option>
                </select>
              </div>

              <div className="xl:col-span-3">
                <label
                  htmlFor="invoiceID"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Invoice No.
                </label>
                <input
                  type="text"
                  id="invoiceID"
                  name="invoiceNumber"
                  onChange={handleChange}
                  value={formData.invoiceNumber}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter invoice no."
                  required
                />
              </div>
              <div className="xl:col-span-3">
                <label
                  htmlFor="dateRangeFilterInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Invoice Date
                </label>
                <Flatpickr
                  id="invoiceDate"
                  name="invoiceDate"
                  onChange={onInvoiceDateChange}
                  value={formData.invoiceDate}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  options={{
                    dateFormat: "d M, Y",
                  }}
                  placeholder="Invoice date"
                />
              </div>

              <div className="xl:col-span-3">
                <label
                  htmlFor="poNumber"
                  className="inline-block mb-2 text-base font-medium"
                >
                  PO Number
                </label>
                <input
                  type="text"
                  id="PONumber"
                  name="PONumber"
                  onChange={handleChange}
                  value={formData.PONumber}
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="PO number"
                  required
                />
              </div>

              <div className="xl:col-span-3">
                <label
                  htmlFor="paidType"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Paid Type
                </label>
                <div className="flex items-center gap-2">
                  <input
                    id="paidType"
                    onChange={handleChange}
                    value={formData.paidType}
                    className="size-4 border rounded-sm appearance-none cursor-pointer bg-slate-100 border-slate-200 dark:bg-zink-600 dark:border-zink-500 checked:bg-custom-500 checked:border-custom-500 dark:checked:bg-custom-500 dark:checked:border-custom-500 checked:disabled:bg-custom-400 checked:disabled:border-custom-400"
                    type="checkbox"
                    defaultChecked
                  />
                  <label htmlFor="paidType" className="align-middle">
                    Cash / ACH
                  </label>
                </div>
              </div>
            </div>

            <div className="flex justify-between gap-2 mt-5">
              <h6 className="mt-4 mb-2 text-gray-800 underline text-16 dark:text-zink-50">
                Accounts Info:
              </h6>
              <button
                type="button"
                onClick={addAccount}
                className="bg-white border-dashed text-custom-500 btn border-custom-500 hover:text-custom-500 hover:bg-custom-50 hover:border-custom-600 focus:text-custom-600 focus:bg-custom-50 focus:border-custom-600 active:text-custom-600 active:bg-custom-50 active:border-custom-600 dark:bg-zink-700 dark:ring-custom-400/20 dark:hover:bg-custom-800/20 dark:focus:bg-custom-800/20 dark:active:bg-custom-800/20"
              >
                <Plus className="inline-block size-3 mr-1 align-middle" />{" "}
                <span className="align-middle">Add Account</span>
              </button>
            </div>

            <div className="mt-2 overflow-x-auto">
              <table className="w-full whitespace-nowrap">
                <thead className="ltr:text-left rtl:text-right">
                  <tr>
                    <th className="px-3.5 py-2.5 font-semibold text-slate-500 dark:text-zink-200 border-b border-slate-200 dark:border-zink-500">
                      Account Name
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold text-slate-500 dark:text-zink-200 border-b border-slate-200 dark:border-zink-500">
                      Quantity
                    </th>
                    <th className="px-3.5 py-2.5 font-semibold text-slate-500 dark:text-zink-200 border-b border-slate-200 dark:border-zink-500">
                      Total Cost
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.map((account, index) => (
                    <tr key={index}>
                      <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                        <h6 className="mb-1">{account.accountName}</h6>
                      </td>
                      <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                        {account.quantity}
                      </td>
                      <td className="px-3.5 py-2.5 border-b border-slate-200 dark:border-zink-500">
                        {account.totalCost}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="flex justify-end gap-2 mt-5">
              <button
                type="button"
                className="text-slate-500 btn bg-slate-200 border-slate-200 hover:text-slate-600 hover:bg-slate-300 hover:border-slate-300 focus:text-slate-600 focus:bg-slate-300 focus:border-slate-300 focus:ring focus:ring-slate-100 active:text-slate-600 active:bg-slate-300 active:border-slate-300 active:ring active:ring-slate-100 dark:bg-zink-600 dark:hover:bg-zink-500 dark:border-zink-600 dark:hover:border-zink-500 dark:text-zink-200 dark:ring-zink-400/50"
              >
                <RefreshCcw className="inline-block size-4 mr-1" />{" "}
                <span className="align-middle">Reset</span>
              </button>
              <button
                type="submit"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                <Save className="inline-block size-4 mr-1" />{" "}
                <span className="align-middle">Save</span>
              </button>
              <button
                type="button"
                className="text-white bg-green-500 border-green-500 btn hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/10"
              >
                <Download className="inline-block size-4 mr-1" />{" "}
                <span className="align-middle">Download</span>
              </button>
            </div>

            <Modal
              show={show}
              onHide={toggle}
              id="addAccountModal"
              modal-center="true"
              className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
              dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
            >
              <Modal.Header
                className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
                closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500"
              >
                <Modal.Title className="text-16">Add Account</Modal.Title>
              </Modal.Header>
              <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                <div className="mb-3">
                  <label
                    htmlFor="accountNameInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Account Name
                  </label>

                  <select
                    id="accountName"
                    name="accountName"
                    onChange={handleAccountModalChange}
                    value={newAccount.accountName}
                    className="form-select border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  >
                    <option value="" disabled>
                      Select Account
                    </option>
                    {accountsData.map((account, index) => (
                      <option key={index} value={account.accountName}>
                        {account.accountName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="quantityInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Quantity
                  </label>
                  <input
                    type="number"
                    id="quantityInput"
                    onChange={handleAccountModalChange}
                    value={newAccount.quantity}
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Enter quantity"
                    name="quantity"
                  />
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="totalCostInput"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Total Cost
                  </label>
                  <input
                    type="number"
                    step=".01"
                    id="totalCostInput"
                    onChange={handleAccountModalChange}
                    value={newAccount.totalCost}
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Enter total cost"
                    name="totalCost"
                  />
                </div>
                <div className="flex justify-end gap-2 mt-4">
                  <button
                    type="reset"
                    data-modal-close="addAccountModal"
                    className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                    onClick={toggle}
                  >
                    Close
                  </button>
                  <button
                    type="submit"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    onClick={saveAccount}
                  >
                    Save Account
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddNew;
