import { Award, CalendarDays, CircuitBoard, Codesandbox, FileText, LifeBuoy, LocateFixed, Mail, Map, MessageSquare, MonitorDot, PackagePlus, PictureInPicture2, PieChart, RadioTower, ScrollText, Share2, ShoppingBag, Table, Trophy, UserRound } from "lucide-react";

const menuData: any = [
    {
        label: 'menu',
        isTitle: true,
    },
    {
        id: "dashboard",
        label: 'Dashboards',
        link: "/#",
        icon: <MonitorDot />,
        subItems: [
            {
                id: 'analyticsdashboard',
                label: 'Sales',
                link: '/dashboard',
                parentId: "dashboard"
            }
        ]
    },
    {
        id: 'invoice',
        label: 'Invoices',
        icon: <FileText />,
        parentId: 2,
        subItems: [
            {
                id: 'invoicelistview',
                label: 'Invoice List',
                link: '/apps-invoice-list',
                parentId: 'invoice'
            },
            {
                id: 'invoiceaddnew',
                label: 'Add New Invoice',
                link: '/apps-invoice-add-new',
                parentId: 'invoice'
            },
            {
                id: 'invoiceaddnew',
                label: 'Upload Invoice',
                link: '/apps-invoice-upload',
                parentId: 'invoice'
            }
        ]
    }
];

export { menuData };