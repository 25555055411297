import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../DateRange/DateRangeFilter.scss";

import { DateRangePicker, createStaticRanges } from "react-date-range";
import {
    subDays,
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    endOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    startOfYear,
    endOfYear,
    addYears
} from "date-fns";
import { wrap } from "module";

const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1))
};

export const sideBarOptions = () => {
    const customDateObjects = [
        {
            label: DateRangeLabel.Today,
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday
            })
        },
        {
            label: DateRangeLabel.Yesterday,
            range: () => ({
                startDate: defineds.startOfYesterday,
                endDate: defineds.endOfYesterday
            })
        },
        {
            label: DateRangeLabel.Last7Days,
            range: () => ({
                startDate: defineds.startOfLastSevenDay,
                endDate: defineds.endOfToday
            })
        },
        {
            label: DateRangeLabel.Last30Days,
            range: () => ({
                startDate: defineds.startOfLastThirtyDay,
                endDate: defineds.endOfToday
            })
        },
        {
            label: DateRangeLabel.ThisMonth,
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfMonth
            })
        },
        {
            label: DateRangeLabel.LastMonth,
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth
            })
        },
    ];

    return customDateObjects;
};

export enum DateRangeLabel {
    Today = "Today",
    Yesterday = "Yesterday",
    Last7Days = "Last 7 Days",
    Last30Days = "Last 30 Days",
    ThisMonth = "This Month",
    LastMonth = "Last Month",
}

interface DateRangeFilterProps {
    onChange: (ranges: any) => void;
    onApply: () => void;
    defaultSelection: DateRangeLabel;
}

const DateRangeFilter = ({ onChange, onApply, defaultSelection } : DateRangeFilterProps) => {

    const sideBar = sideBarOptions();
    const staticRanges = [
        // ...defaultStaticRanges,
        ...createStaticRanges(sideBar)
    ];

    const defaultSelectionRange = sideBarOptions().find((item) => item.label === defaultSelection)?.range(); 

    const [state, setState] = useState([
        {
            startDate: defaultSelectionRange?.startDate,
            endDate: defaultSelectionRange?.endDate,
            key: "selection"
        }
    ]);

    useEffect(() => {
        const span = document.createElement('span');
        span.style.flex = '1 1';
        span.style.marginLeft = '0.833em';

        const button = document.createElement('button');
        button.innerHTML = 'Apply';
        button.className = 'text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20';
        button.type = 'button';
        button.onclick = onApply;
        span.appendChild(button);

        const wrapper = document.querySelector('.rdrDateDisplay');
        wrapper?.appendChild(span);

        return () => {
            wrapper?.removeChild(span);
        }
    }, []);

    const handleOnChange = (ranges : any) => {
        const { selection } = ranges;
        onChange(selection);
        setState([selection]);
    };

    return (
        <DateRangePicker
            onChange={handleOnChange}
            rangeColors={['rgba(55, 176, 76, 1)']}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={state}
            staticRanges={staticRanges}
            direction="horizontal"
        />
    );
};

DateRangeFilter.propTypes = {
    onChange: PropTypes.func,
    onApply: PropTypes.func
};

export default DateRangeFilter;
