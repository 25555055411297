import React, { useState, useEffect, useRef } from "react";
import BreadCrumb from "Common/BreadCrumb";
import Widgets from "./Widgets";
import LocationBased from "./LocationBased";
import Interaction from "./Interaction";
import UserDevice from "./UserDevice";
import Satisfaction from "./Satisfaction";
import DailyVisit from "./DailyVisit";
import Reports from "./Reports";
import MonthlyCampaign from "./MonthlyCampaign";
import Subscription from "./Subscription";
import TrafficSource from "./TrafficSource";
import ProductsStatistics from "./ProductsStatistics";

import DefaultWithout from "../../Components/Table/Basic/DefaultWithout"; // Add this line
import EvenOdd from "../../Components/Table/Basic/EvenOdd";
import HoverAbleStripedRows from "../../Components/Table/Basic/HoverAbleStripedRows";
import BorderBordered from "../../Components/Table/Basic/BorderBordered";
import ColorsTable from "../../Components/Table/Basic/ColorsTable";
import CustomCardTable from "../../Components/Table/Basic/CustomCardTable";
import TwoColumn from "pages/Components/TwoColumn";
import Grid from "pages/Components/Grid";
import DateRangeFilter, { DateRangeLabel, sideBarOptions } from "Common/Components/DateRange/DateRangeFilter";
import Calendar from "../../../assets/images/calendar.png";
import { FaChevronDown, FaChevronUp, FaEllipsisV } from "react-icons/fa";
import moment from "moment";
import SummaryCards from "./SummaryCards";

const Analytics = () => {
  const range = sideBarOptions().find((item) => item.label === DateRangeLabel.ThisMonth)?.range(); 

  const [dashboardData, setDashboardData] = useState([]);
  const [startDate, setStartDate] = React.useState(range?.startDate);
  const [endDate, setEndDate] = React.useState(range?.endDate);
  const [isOpen, setIsOpen] = useState(false);
  const fileRef = useRef(null);

  const onChange = (ranges: any) => {
    setStartDate(ranges.startDate);
    setEndDate(ranges.endDate);
  };

  const toggle = () => setIsOpen(!isOpen);

  const onApply = () => {
    setIsOpen(false);
  }

  useEffect(() => {
    if (!isOpen) {
      fetchDashboardData();
    }
  }, [startDate, endDate, isOpen]);

  async function fetchDashboardData() {
    const url = process.env.REACT_APP_SERVER_ADDRESS + `/api/dashboard/main?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`;
    
    const response = await fetch(url);
    const dashboardData = await response.json();
    console.log(dashboardData);
    setDashboardData(dashboardData);
  }

  function uploadCsvFile() {
    (fileRef?.current as any).click();
  }

  async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    console.log('File uploaded', event.target.files);
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await fetch(process.env.REACT_APP_SERVER_ADDRESS +
          `/api/document`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          console.log('File uploaded successfully');
        } else {
          console.error('Failed to upload file');
        }
      } catch (error) {
        console.error('Network error occurred', error);
      }
    }
  }

  return (
    <React.Fragment>
      <BreadCrumb title="Sales" pageTitle="Dashboards" />

      <div className="grid grid-cols-12 gap-x-5">
        <div className="xl:col-span-5">
          <div className="date-box">
            <button
              onClick={toggle}
              style={{ marginBottom: "1rem" }}
              className="date-content"
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <img src={Calendar} alt="" />
                <span>Period:</span>
                <span>
                  {moment(startDate).format("DD MMMM YYYY")}
                </span>
                <span>
                  -
                </span>
                <span>
                  {moment(endDate).format("DD MMMM YYYY")}
                </span>
                <FaChevronDown />
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-x-5">
        <div hidden={!isOpen}>
          <div className="date-line">
            <div>
              <DateRangeFilter onChange={onChange} onApply={onApply} defaultSelection={DateRangeLabel.ThisMonth} />
            </div>
          </div>
        </div>
      </div>
     
      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        <BreadCrumb title="Sales Summary" pageTitle="Sales" />

        {dashboardData != null ? (
          <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
            {dashboardData.map((card: any, index) => {
              return card.type === "TwoColumn" ? (
                <TwoColumn key={index} data={card} />
              ) : (
                <Grid key={index} data={card} />
              );
            })}
          </div>
        ) : null}        
      </div>     

    
      <div className="grid grid-cols-12 gap-x-5">
      
      </div>

      <div className="container-fluid group-data-[content=boxed]:max-w-boxed mx-auto">
        

        <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-2">
         
        </div>
      </div>
    </React.Fragment>
  );
};

export default Analytics;
