import React from "react";
const TwoColumn = ({ data }: any) => {
    const rows = data.fields.map((field: any, index: number) => {
      return {
        name: field.fieldName,
        value: data.records.length > 0 ? data.records[0][field.fieldName] : 0,
      };
    });
  
    const Currency = (value:any) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return <span>{formatter.format(value)}</span>;
    };

    return (
      <div className="card">
          <div className="card-body">
              <h6 className="mb-4 text-15">{data.title}</h6>
  
              <div className="overflow-x-auto">
                  <table className="w-full">
                      <tbody>
                          {
                              rows.map((row: any, rowIndex: number) => {
                                  return (
                                      <tr key={rowIndex} className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600">
                                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500">
                                              { row.name }
                                          </td>
                                          <td className="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500 text-right">
                                             {Currency(row.value)}
                                          </td>
                                      </tr>
                                  )
                              })
                          }
                          
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
    );
  };
  
  export default TwoColumn;
  