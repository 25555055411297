import React from "react";
const Grid = ({ data } : any) => {
    const columns = data.fields.map((field: any, index: number) => {
        return {
            field: field.fieldName,
            header: field.fieldName
        }
    });

    const Currency = (value:any) => {
        if (isNaN(value)) {
            return <span>{value}</span>;
          }
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return <span>{formatter.format(value)}</span>;
    };    

    return (
        <React.Fragment>
        <div className="card">
            <div className="card-body">
                <h6 className="mb-4 text-15">{data.title}</h6>

                <div className="overflow-x-auto">
                    <table className="w-full">
                        <thead className="ltr:text-left rtl:text-right ">
                            <tr>
                                {
                                    columns.map((column: any, columnIndex: number) => {
                                        return  <th key={columnIndex} 
                                        className=
                                        {columnIndex === 0 
                                            ? "px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                                            : "px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500 text-right"
                                        }                                        
                                        >{column.header}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody className="">
                            {
                                data.records.map((record: any, recordIndex: number) => {
                                    return (
                                        <tr  key={recordIndex} className="even:bg-slate-50 hover:bg-slate-50 even:hover:bg-slate-100 dark:even:bg-zink-600/50 dark:hover:bg-zink-600 dark:even:hover:bg-zink-600">
                                            {
                                                columns.map((column: any, columnIndex: number) => {

                                                    return <td key={recordIndex.toString() + columnIndex.toString()}  className=
                                                    {columnIndex === 0 
                                                        ? "px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500 "
                                                        : "px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500 text-right"
                                                    }>
                                                    {columnIndex === 0 
                                                        ? record[column.field] 
                                                        : Currency(record[column.field])
                                                    }
                                                    </td>
                                                })
                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </React.Fragment>
    );
};

export default Grid;