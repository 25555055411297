import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url_helper";

class InfoDriveService {
  baseUrl: string | undefined;
  constructor() {
    this.baseUrl = process.env.REACT_APP_SERVER_ADDRESS;
  }

  async loginUser(email: string, password: string) {
    const url = `${this.baseUrl}${process.env.REACT_APP_INFODRIVE_LOGIN}`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "email": email,
            "password": password,
        }
    });

    return { status: response.status, message: await response.json()};
  }
}

export default InfoDriveService;